import { atom, useAtomValue } from 'jotai';
import type { Schema, ZodEffects, ZodObject } from 'zod';

import { getObjectFormSchema } from '~/utils/zod';

// biome-ignore lint/suspicious/noExplicitAny: required to be generic
type AtomSchema = Schema<any, any>;

// biome-ignore lint/suspicious/noExplicitAny: required to be generic
type AtomZodObject = ZodObject<any, any>;

// biome-ignore lint/suspicious/noExplicitAny: required to be generic
type AtomZodEffects = ZodEffects<any, any>;

export const schemaAtom = atom<AtomSchema | undefined>(undefined);
export const defaultValueAtom = atom<Record<string, unknown> | undefined>(undefined);

const isZodObject = (schema: AtomSchema): schema is AtomZodObject => schema._def.typeName === 'ZodObject';
const isZodEffects = (schema: AtomSchema): schema is AtomZodEffects => schema._def.typeName === 'ZodEffects';

export const useSchema = (name: string): AtomSchema | undefined => {
  let schema = useAtomValue(schemaAtom);
  if (!schema) return undefined;

  if (isZodEffects(schema)) schema = getObjectFormSchema(schema);

  if (isZodObject(schema)) {
    return schema.shape[name];
  }

  return schema;
};

export const useDefaultValues = <T = unknown>(name: string): T | undefined => {
  const value = useAtomValue(defaultValueAtom);
  if (!value) return undefined;

  return value[name] as T;
};

export const useIsRequired = (name: string) => {
  const schema = useSchema(name);

  if (!schema) return undefined;
  return !['ZodOptional', 'ZodNullable'].includes(schema._def.typeName);
};
