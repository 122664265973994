import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { HTMLAttributes, InputHTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import { forwardRef, useState } from 'react';
import { cn } from '~/libs/utils';

import { Button } from '~/components/ui/button';

import { ApplicationIcon } from '~/components/application-icons';

export type InputProps = InputHTMLAttributes<HTMLInputElement>;
export const Input = forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => (
  <input
    type={type}
    className={cn(
      'flex h-8 w-full rounded-md border border-input bg-background px-3 py-2 text-sm shadow-sm ring-offset-background transition-shadow file:border-0 file:bg-transparent file:font-medium file:text-sm placeholder:text-muted-foreground hover:shadow focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
      className
    )}
    ref={ref}
    {...props}
  />
));
Input.displayName = 'Input';

export type FileInputProps = Omit<InputProps, 'type'>;
export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(({ className, ...props }, ref) => (
  <input
    type='file'
    className={cn(
      'absolute top-0 left-0 h-full w-full overflow-hidden opacity-0 shadow-sm transition-shadow hover:cursor-pointer hover:shadow hover:disabled:cursor-not-allowed',
      className
    )}
    ref={ref}
    {...props}
  />
));
FileInput.displayName = 'FileInput';

export type InputWithIconProps = InputHTMLAttributes<HTMLInputElement> & {
  append?: ReactNode;
  onAppendClick?: MouseEventHandler<HTMLSpanElement>;
  prepend?: ReactNode;
  onPrependClick?: MouseEventHandler<HTMLSpanElement>;
  background?: VariantProps<typeof InputIconVariants>['background'];
};

const InputIconVariants = cva(
  'pointer-events-none flex h-8 select-none items-center border-input border-y p-1 pl-3 text-center text-foreground text-sm shadow-none hover:shadow-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      position: {
        right: 'rounded-r-md border-r',
        left: 'rounded-l-md border-l',
      },
      background: {
        off: 'bg-transparent',
        on: 'bg-muted text-muted-foreground',
      },
    },
    compoundVariants: [
      {
        position: 'left',
        background: 'on',
        class: 'border-r',
      },
      {
        position: 'right',
        background: 'on',
        class: 'border-l',
      },
    ],
    defaultVariants: {
      position: 'right',
    },
  }
);

const InputIcon = forwardRef<HTMLSpanElement, VariantProps<typeof InputIconVariants> & HTMLAttributes<HTMLSpanElement>>(
  ({ position, children, ...props }, ref) => {
    if (!children) return null;

    return (
      <span ref={ref} className={InputIconVariants({ position })} {...props}>
        {children}
      </span>
    );
  }
);
InputIcon.displayName = 'InputIcon';

export const InputWithIcon = forwardRef<HTMLInputElement, InputWithIconProps>(
  ({ className, type, append, onAppendClick, prepend, onPrependClick, background, ...props }, ref) => {
    if (!append && !prepend) {
      return <Input className={className} type={type} {...props} ref={ref} />;
    }

    return (
      <div className='flex items-center rounded-md bg-background shadow-sm ring-offset-background transition-shadow hover:shadow [&:has(input:disabled)]:cursor-not-allowed [&:has(input:disabled)]:opacity-50 [&:has(input:focus-visible)]:outline-none [&:has(input:focus-visible)]:ring-2 [&:has(input:focus-visible)]:ring-ring [&:has(input:focus-visible)]:ring-offset-2'>
        <InputIcon background={background} position='left' onClick={onPrependClick}>
          {prepend}
        </InputIcon>
        <Input
          type={type}
          className={cn(
            'border border-input px-3 shadow-none hover:shadow-none focus-visible:outline-none focus-visible:ring-0 disabled:opacity-100',
            {
              'rounded-none border-x-0': append && prepend,
              'rounded-none rounded-l-md border-r-0': append && !prepend,
              'rounded-none rounded-r-md border-l-0 pr-3 pl-2': !append && prepend,
            },
            className
          )}
          ref={ref}
          {...props}
        />
        <InputIcon background={background} position='right' onClick={onAppendClick}>
          {append}
        </InputIcon>
      </div>
    );
  }
);
InputWithIcon.displayName = 'InputWithIcon';

export type PasswordProps = Omit<InputProps, 'type'>;
export const Password = forwardRef<HTMLInputElement, PasswordProps>(({ className, ...props }, ref) => {
  const [type, setType] = useState<'text' | 'password'>('password');

  return (
    <div className='relative'>
      <Input className={cn('pr-10', className)} ref={ref} {...props} type={type} />

      <span className='absolute inset-y-0 right-0 flex items-center'>
        <Button
          className='size-10 select-none py-1 focus:outline-none'
          tabIndex={-1}
          variant='ghost'
          type='button'
          onClick={() => setType(val => (val === 'text' ? 'password' : 'text'))}
        >
          {type === 'text' ? <ApplicationIcon icon='hide' /> : <ApplicationIcon icon='view' />}
        </Button>
      </span>
    </div>
  );
});
Password.displayName = 'Password';
