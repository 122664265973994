/* eslint-disable no-self-compare */
// noinspection SuspiciousTypeOfGuard
import { parseISO } from 'date-fns';
import type { DefaultValues, FieldValues } from 'react-hook-form';
import * as z from 'zod';

import { isValueDate } from '~/utils/is-value-date';

export const getSchema = <T extends z.Schema = z.Schema>(schema: T, key: keyof z.output<T>): z.SomeZodObject => {
  const baseSchema = getBaseSchema(schema);
  if (baseSchema instanceof z.ZodObject) return baseSchema.shape[key] as unknown as z.SomeZodObject;
  if (baseSchema instanceof z.ZodArray) return baseSchema.element as unknown as z.SomeZodObject;
  return baseSchema as unknown as z.SomeZodObject;
};

/**
 * Get all keys from a zod schema
 * @param schema
 */
export const zodKeys = <T extends z.ZodTypeAny>(schema: T | null | undefined): string[] => {
  // make sure schema is not null or undefined
  if (schema === null || schema === undefined) return [];
  // check if schema is nullable or optional
  if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional) return zodKeys(schema.unwrap());
  // check if schema is an array
  if (schema instanceof z.ZodArray) return zodKeys(schema.element);
  // check if schema is an object
  if (schema instanceof z.ZodObject) {
    // get key/value pairs from schema
    const entries = Object.entries(schema.shape);
    // loop through key/value pairs
    return entries.flatMap(([key, value]) => {
      // get nested keys
      const nested = value instanceof z.ZodType ? zodKeys(value).map(subKey => `${key}.${subKey}`) : [];
      // return nested keys
      return nested.length ? nested : key;
    });
  }
  // check if schema is an effect
  if (schema instanceof z.ZodEffects) {
    const typedSchema = schema as z.ZodEffects<ZodObject>;
    return zodKeys(typedSchema._def.schema);
  }

  // return empty array
  return [];
};

/**
 * Get the lowest level Zod type.
 * This will unpack optionals, refinements, etc.
 */
export function getBaseSchema<T extends z.Schema = z.Schema>(schema: z.ZodOptional<T> | z.ZodEffects<T> | T): T {
  if (schema instanceof z.ZodOptional || schema instanceof z.ZodNullable) {
    return getBaseSchema(schema.unwrap());
  }
  if (schema instanceof z.ZodEffects) {
    return getBaseSchema(schema._def.schema as T);
  }
  if ('innerType' in schema._def) {
    return getBaseSchema(schema._def.innerType as T);
  }
  if ('schema' in schema._def) {
    return getBaseSchema(schema._def.schema as T);
  }
  return schema;
}

// biome-ignore lint/suspicious/noExplicitAny: this is completely safe
type ZodObject = z.ZodObject<any, any>;

function isFormArray<T>(val: T[] | unknown, keys: string[], key: string): val is T[] {
  return Array.isArray(val) && !!keys.find(k => k.startsWith(`${key}.`));
}

export function getDefaultFormValues<Schema extends z.Schema, TFieldValues extends FieldValues = FieldValues>(
  schema: Schema,
  value: TFieldValues
): DefaultValues<TFieldValues> | undefined {
  const keys = zodKeys(schema);
  const baseSchema = getBaseSchema(schema);
  return Object.entries(value).reduce(
    (current, [key, val]) => {
      if (isFormArray(val, keys, key)) {
        val = val as TFieldValues[];
        return val.reduce(
          (previousValue: TFieldValues, v: Partial<TFieldValues>) => {
            const objSchema = baseSchema as unknown as ZodObject;
            const arraySchema = getSchema(objSchema, key) as unknown as z.ZodArray<z.SomeZodObject>;
            const found = getDefaultFormValues(arraySchema.element, v);
            // @ts-expect-error
            previousValue[key] = previousValue[key] ? [...previousValue[key], found] : [found];
            return previousValue as DefaultValues<TFieldValues>;
          },
          current as DefaultValues<TFieldValues>
        );
      }

      if (typeof val === 'object' && keys.find(k => k.startsWith(`${key}.`))) {
        const objSchema = baseSchema as unknown as ZodObject;
        const objectSchema = getSchema(objSchema, key);
        const found: DefaultValues<TFieldValues> = getDefaultFormValues(objectSchema, val);
        return Object.entries(found).reduce(
          (prev, [k, v]) => {
            prev[`${key}.${k}` as keyof DefaultValues<TFieldValues>] = v;
            return prev;
          },
          current as DefaultValues<TFieldValues>
        );
      }

      const parse = <TKey extends keyof TFieldValues, TValue extends TFieldValues[TKey]>(
        key: TKey,
        val: TValue
      ): TValue | Date => {
        if (typeof val === 'string') {
          const date = parseISO(val);
          const objSchema = baseSchema as unknown as ZodObject;
          if (objSchema.shape[key] instanceof z.ZodDate && isValueDate(date)) return date;
        }
        return val;
      };

      const currentKey = key as keyof DefaultValues<TFieldValues>;
      if (keys.includes(key)) current[currentKey] = parse(key, val);
      return current;
    },
    {} as DefaultValues<TFieldValues>
  );
}

export function getObjectFormSchema(schema: ZodObjectOrWrapped): ZodObject {
  if (schema._def.typeName === 'ZodEffects') {
    const typedSchema = schema as z.ZodEffects<ZodObject>;
    return getObjectFormSchema(typedSchema._def.schema);
  }
  return schema as ZodObject;
}

// TODO: This should support recursive ZodEffects but TypeScript doesn't allow circular type definitions.
export type ZodObjectOrWrapped = ZodObject | z.ZodEffects<ZodObject>;
